import { useState } from 'react';
import RingLoader from 'react-spinners/RingLoader';
import styled from 'styled-components';

export default function Loader() {
  let [loadingInProgress] = useState(true);

  return (
    <StyledLoader>
      <RingLoader color={'var(--alu)'} loading={loadingInProgress} size={110} />
    </StyledLoader>
  );
}

const StyledLoader = styled.div`
  height: 100vh;
  width: 100vw;

  display: block;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.primaryBackgroundColor};
`;
