import { createGlobalStyle } from 'styled-components';

// the styled-components global styles object
// import in App.js and inserted as a Component
// applies every style defined in here to all elements (can still be overwritten)

// css improvements based on: https://github.com/AllThingsSmitty/css-protips
export const GlobalStyles = createGlobalStyle`

		:root {
			--black1: #111111;
			--black2: #101010;
			--black3: #121212;
			--black4: #161616;
			--black5: #191919;
			--black6: #1A1A1A;
			--black7: #1C1C1C;
			--black8: #1E1E1E;
			--black9: #202020;
			--black10: #282828;
			--black11: #2C2C2C;
			--black12: #2F2F2F;
			--white1: #F8FAFC;
			--white2: #F7FAFC;
			--white3: #F9FAFC;
			--white4: #F1F5F9;
			--white5: #F9FAFB;
			--white6: #FAFAFA;
			--white7: #FAFAF9;
			--white8: #F6F6F8;
			--white9: #F5F5F4;
			--white10: #F7F7F9;
			--white11: #F8F8F8;
			--white12: #F2F2F2;
			--mil-tint-1: #5B6E8B;
			--mil-tint-2: #70809A;
			--mil-tint-3: #8492A8;
			--mil-tint-4: #99A5B7;
			--mil-tint-5: #ADB7C5;
			--mil-tint-6: #C2C9D4;
			--mil-tint-7: #D6DBE2;
			--mil-tint-8: #EBEDF1;
			--mil-tone-1: #425572;
			--mil-tone-2: #495A73;
			--mil-tone-3: #516075;
			--mil-tone-4: #596577;
			--mil-tone-5: #616A79;
			--mil-tone-6: #69707B;
			--mil-tone-7: #70757C;
			--mil-tone-8: #787B7E;
			--mil-shade-1: #283B58;
			--mil-shade-2: #23344D;
			--mil-shade-3: #1E2C42;
			--mil-shade-4: #192537;
			--mil-shade-5: #141E2C;
			--mil-shade-6: #0F1621;
			--mil-shade-7: #0A0F16;
			--mil-shade-8: #05070B;
			--dark: #212121;
			--alu: #DCE4EF;
			--mud: #494440;
			--yellow: #F8DE00;
			--gray-500: #323A45;
			--gray-400: #5B616B;
			--gray-300: #AEB0B5;
			--gray-200: #D6D7D9;
			--gray-150: #E4E2E0;
			--gray-100: #F1F1F1;
			--cta-100: #0071BC;
			--cta-200: #205493;
			--cta-300: #112E51;
			--whiter: #fffff8;
		}

		html{
				box-sizing: border-box;
				scrollbar-gutter: stable;
				color: var(--alu);
		}

		*,
		*::before,
		*::after {
				margin: 0;
				border: 0;
				padding: 0;
				-moz-box-sizing: inherit;
				-webkit-box-sizing: inherit;
				box-sizing: inherit;
		}

		body {
				height: 100%;
				margin: 0;
				font-family: -apple-system,
						BlinkMacSystemFont,
						"Segoe UI",
						"Roboto",
						"Oxygen",
						"Ubuntu",
						"Cantarell",
						"Fira Sans",
						"Droid Sans",
						"Helvetica Neue",
						sans-serif;
				font-size: 1rem;
				line-height: 1.6;
				font-weight: 100;
		}

		/* Display links when the <a> element has no text value but the href attribute has a link */
		a[href^="https"]:empty::before {
				content: attr(href);
		}

		/* Add a style for "default" links without a class attribute */
		a[href]:not([class]) {
				color: var(--alu);
				text-decoration: underline;
		}

		h1, h2, h3 {
				font-weight: 400;
		}

		h4, h5, h6 {
				font-weight: 300;
		}

		h1 {
				font-size: 2em;
		}

		h2 {
				font-size: 1.8em;
		}

		h3 {
				font-size: 1.5em;
		}

		h4 {
				font-size: 1.3em;
		}

		h5 {
				font-size: 1.2em;
		}

		h6 {
				font-size: 1em;
		}

		button {
				all: unset;
		}

		button, html input[type="button"], input[type="reset"], input[type="submit"] {
				-webkit-appearance: button;
				cursor: pointer
		}

		ul {
				margin: 1rem;

		}
		
		ol {
				margin: 1rem;

		}

		li {
				padding-top: 0.5rem;
		}

		a:focus,
		button:focus,
		input:focus,
		select:focus,
		textarea:focus {
				box-shadow: none;
				outline: var (--gray-400) dotted 2px;
				outline-offset: .05em;
		}

		/* avoid mobile browsers from zooming in on HTML form elements */
		input[type="text"],
		input[type="number"],
		select,
		textarea {
				font-size: 16px;
				line-height: normal
		}

		textarea {
				overflow: auto
		}

		img {
			max-width: 100%;
			height: auto;

			vertical-align: middle;
			background-repeat: no-repeat;
			background-size: cover;

			image-rendering: -webkit-optimize-contrast;
			shape-margin: 0.75rem;

			font-family: sans-serif;
			font-style: italic;
				
			border: 0;	
		}

		/* pseudo-elements rules to display a user message and URL reference of the broken image */
		img::before {
				content: "We're sorry, the image below is broken :(";
				display: block;
				margin-bottom: 1em;
		}

		img::after {
				content: "(url: " attr(src) ")";
				display: block;
				font-size: 1em;
		}

		svg:not(:root) {
				overflow: hidden
		}

		/* SVG icon-only buttons for sighted users and the SVG fails to load, this will help maintain accessibility */
		.no-svg .icon-only::after {
				content: attr(aria-label);
		}

		figure {
				margin: 0
		}

		hr {
			margin: 0.2rem;
			border: solid 1px var(--mud)!important;
		}

    /* for reducing the opacity of deactived Marker Icons */
	  .deactivated {
		opacity: 0.5 ;
	  }

		@media (prefers-reduced-motion: reduce) {
				.animation {
						animation: none;
				}  
      }
`;

const colors = {
  attentionColor: '#F8DE00',
  alertColor: '#D2251E',
  readyColor: '#4CBA4B',
};

const borderConstants = {
  inputBorderRadius: '20px',
  primaryBorderRadius: '10px',
  drawerBorderRadius: '50px',
  buttonBorderRadius: '5px',
};

const mediaQueries = {
  mediaQueryPhone: '(max-width: 43em), screen or (orientation: portrait)',
  mediaQueryTablet: '(44em <= width <= 62em), screen',
  mediaQueryDesktop: '(63em <= width <= 87em), screen',
  mediaQueryWidescreen: '(min-width: 88em), screen',
  mediaQueryWearable: '(max-width: 25em), screen',
};

const font = {
  calculatedFontSize: 'calc(1vw + 1vh + .5vmin)',
  primaryLineHeight: '1.6rem',
};

const paddings = {
  insideButtonPadding: '0.2rem 0.5rem 0.2rem 0.5rem',
  sectionHeadlinePadding: '0.2rem 0 0.2rem 1rem',
  drawPadding: '1.5rem',
};

export const darkTheme = {
  primaryBackgroundColor: 'var(--dark)',
  primaryFontColor: 'var(--alu)',
  secondaryBackgroundColor: 'var(--mud)',
  secondaryFontColor: 'var(--alu)',
  buttonColor: 'var(--mud)',
  buttonFontColor: 'var(--alu)',
  footerFontColor: 'var-(--gray-400)',
  yellowCtaBackgroundColor: 'var(--yellow)',
  yellowCtaFontColor: 'var(--dark)',
  blueCtaBackgroundColor: 'var(--cta-300)',
  blueCtaFontColor: 'var(--cta-100)',
  ...borderConstants,
  ...colors,
  ...mediaQueries,
  ...font,
  ...paddings,
};

export const lightTheme = {
  primaryBackgroundColor: 'var(--alu)',
  primaryFontColor: 'var(--dark)',
  secondaryBackgroundColor: 'var(--gray-150)',
  secondaryFontColor: 'var(--mud)',
  buttonColor: 'var(--gray-200)',
  buttonFontColor: 'var(--gray-400)',
  footerFontColor: 'var-(--gray-400)',
  yellowCtaBackgroundColor: 'var(--yellow)',
  yellowCtaFontColor: `var(--mud)`,
  blueCtaBackgroundColor: 'var(--cta-100)',
  blueCtaFontColor: 'var(--cta-300)',
  ...borderConstants,
  ...colors,
  ...mediaQueries,
  ...font,
  ...paddings,
};

// Example
// const StyledMapContainer = styled(MapContainer)`
//   height: 100vh;
//   width: 100vw;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
//   background-color: ${(props) => props.theme.primaryBackgroundColor};
//   border-radius: ${(props) => props.theme.primaryBorderRadius};

//   @media (max-width: ${(props) => props.theme.mediaQueryPhone}) {

//   }

//   @media (max-width: ${(props) => props.theme.mediaQueryTablet}) {

// }

// `;
