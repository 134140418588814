import React, { Suspense, lazy, useEffect } from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

// Theming imports
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, darkTheme, lightTheme } from './styles/GlobalStyles';
import styled from 'styled-components';

// Section imports
const NavBar = lazy(() => import('./components/Nav/Navbar'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Meetup = lazy(() => import('./components/pages/Meetup'));
//const Beta = lazy(() => import('./components/pages/Beta'));
const NotFound = lazy(() => import('./components/pages/NotFound'));

// Legals imports
const LegalNotice = lazy(() => import('./components/Legals/LegalNotice'));
const Whistleblowing = lazy(() => import('./components/Legals/Whistleblowing'));
const ResponsabilityDisclosure = lazy(() =>
  import('./components/Legals/ResponsabilityDisclosure')
);
const DataPrivacyPolicy = lazy(() =>
  import('./components/Legals/DataPrivacyPolicy')
);
const WebsitePrivacy = lazy(() => import('./components/Legals/WebsitePrivacy'));
const DataIntegrations = lazy(() =>
  import('./components/Legals/DataIntegrations')
);
const CookiePolicy = lazy(() => import('./components/Legals/CookiePolicy'));
const LicenseAgreement = lazy(() =>
  import('./components/Legals/LicenseAgreement')
);

// Fallback import
const Loader = lazy(() => import('./components/Loader'));

function App() {
  const [selectedTheme, setSelectedTheme] = useState(darkTheme);

  useEffect(() => {
    const currentTheme = JSON.parse(localStorage.getItem('current-theme'));
    if (currentTheme) {
      setSelectedTheme(currentTheme);
    }
  }, []);

  return (
    <>
      <GlobalStyles />

      {/* pass the appropriate global values for the current theme */}
      <ThemeProvider
        theme={selectedTheme === 'darkTheme' ? lightTheme : darkTheme}
      >
        <Router>
          <Suspense
            fallback={
              <React.Fragment>
                <Loader />
              </React.Fragment>
            }
          >
            <AppContainer>
              <NavBar />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                {/**<Route path="/beta-program" element={<Beta />} />*/}
                <Route path="/meetup" element={<Meetup />} />
                <Route path="/legal-notice" element={<LegalNotice />} />
                <Route path="/whistleblowing" element={<Whistleblowing />} />
                <Route
                  path="/responsability-disclosure"
                  element={<ResponsabilityDisclosure />}
                />
                <Route path="/data-privacy" element={<DataPrivacyPolicy />} />
                <Route path="/website-privacy" element={<WebsitePrivacy />} />
                <Route
                  path="/data-integrations"
                  element={<DataIntegrations />}
                />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route
                  path="/license-agreement"
                  element={<LicenseAgreement />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>

              <Footer />
            </AppContainer>
          </Suspense>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
`;
